import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import NavBar from './NavBar';
import Footer from './Footer';

import './styles.css';

// import { rhythm, scale } from '../utils/typography';
const mode = 'dark';

const fontFamilyMetropolis = {
  fontFamily: [
    'Metropolis',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(','),
  letterSpacing: '0.015rem',
};

let theme = createTheme({
  typography: {
    fontFamily: ['Merriweather', 'Georgia', 'serif'].join(','),
    h1: {
      ...fontFamilyMetropolis,
    },
    h2: {
      ...fontFamilyMetropolis,
    },
    h3: {
      ...fontFamilyMetropolis,
    },
    h4: {
      ...fontFamilyMetropolis,
    },
    h5: {
      ...fontFamilyMetropolis,
    },
    h6: {
      ...fontFamilyMetropolis,
    },
    body1: {
      ...fontFamilyMetropolis,
    },
    button: {
      ...fontFamilyMetropolis,
    },
  },
  palette: {
    type: mode,
    primary: {
      // Purple and green play nicely together.
      main: '#2a77de',
    },
    ...(mode === 'dark'
      ? {
          background: {
            default: '#18191a',
            paper: '#242526',
          },
        }
      : {}),
  },
  // palette: {
  //   type: 'dark',
  //   primary: {
  //     main: `#BB86FC`,
  //     // contrastText: '#fff',
  //   },
  // },
});

theme = responsiveFontSizes(theme);

class Layout extends React.Component {
  render() {
    const { location, title, children } = this.props;
    const rootPath = `${__PATH_PREFIX__}/`;
    let header;

    if (location.pathname === rootPath) {
      header = <NavBar />;
    } else {
      header = <NavBar isMain={true} />;
    }
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
          }}
        >
          <header>{header}</header>
          <main>{children}</main>
          <Footer />
        </div>
      </ThemeProvider>
    );
  }
}

export default Layout;
