import React from 'react';
import { Box, Typography, Grid, makeStyles } from '@material-ui/core';
import { Link } from 'gatsby';

const useStyles = makeStyles(theme => ({
  // gridStyle: {
  //   marginTop: `5px`,
  // },
  footer: {
    marginTop: 'auto',
  },
  link: {
    color: '#D3D3D3',
    margin: `10px`,
  },
}));

export default function Footer() {
  const classes = useStyles();
  return (
    <footer className={classes.footer}>
      <Box m={4}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          // spacing={4}
          className={classes.gridStyle}
        >
          <Grid item>
            <Link className={classes.link} to="/terms">
              Terms of Use
            </Link>
          </Grid>
          <Grid item>
            <Link className={classes.link} to="/privacy">
              Privacy Policy
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box m={2}>
        <Typography variant="body2" align="center">
          © {new Date().getFullYear()}, Built by
          {` `}
          <a style={{ color: '#D3D3D3' }} href="https://github.com/Open-SL">
            ChordSL
          </a>
        </Typography>
      </Box>
    </footer>
  );
}
